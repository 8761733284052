import { Hidden } from "@material-ui/core";
import React, { ReactNode } from "react";
import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import MobileNavbar from "../../components/mobile-navbar";
import useStyles from "./styles";

interface DefaultLayoutProps {
  children: ReactNode;
}

function DefaultLayout({ children }: DefaultLayoutProps) {
  const classes = useStyles();
  return (
    <div className={classes.defaultLayout}>
      <div className={classes.navbarArea}>
        <Hidden smDown>
          <Navbar />
        </Hidden>
        <Hidden smUp>
          <MobileNavbar />
        </Hidden>
      </div>
      <div className={classes.contentArea}>{children}</div>
      <div className={classes.footerArea}>
        <Footer />
      </div>
    </div>
  );
}

export default DefaultLayout;
