import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  mobileNavbar: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    paddingLeft: 30,
    paddingTop: 20,
    overflowX: "hidden"
  },
  logo: {
    position: 'absolute',
  },
  mobileNavbarMenuButton: {
    position: "fixed",
    right: 38,
    backgroundColor: "#FFF",
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "#FFF"
    },
    zIndex: 1000
  }
}));

export default useStyles;