import { makeStyles, ThemeProviderProps } from "@material-ui/core";
import defaultBackground from '../../assets/images/default-background.png';

const useStyles = makeStyles(theme => ({
  defaultLayout: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: `url(${defaultBackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundAttachment: 'fixed',
    [theme.breakpoints.up("sm")]: {
      overflowX: "hidden",
    }
  },
  navbarArea: {
    width: '100vw',
    display: 'flex',
  },
  contentArea: {
    width: "100vw",
    flex: 1,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  footerArea: {
    width: '100vw',
    display: 'flex',
    bottom: 0,
  }
}));

export default useStyles;