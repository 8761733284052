import { navigateOnHome, navigateToPage } from "../helpers/navigation-helper/navigation-helper";

interface Link {
  title: string;
  url: string;
  id: string;
  onClick?: Function;
}

const links: Link[] = [
  {
    title: "Sobre nós",
    url: "/",
    id: "sobre-nos",
    onClick: () => navigateToPage("/sobre")
  },
  {
    title: "Como funciona?",
    url: "/",
    id: "como-funciona",
    onClick: () => navigateOnHome("como-funciona")
  },
  {
    title: "O que tem dentro?",
    url: "/",
    id: "dentro",
    onClick: () => navigateOnHome("dentro")
  },
  {
    title: "Planos",
    url: "/",
    id: "planos",
    onClick: () => navigateToPage("/planos")
  },
];

export default links;