import animateScrollTo from "animated-scroll-to";
import { navigate } from 'gatsby';


function isHome(url: string): boolean {
  return !url.includes("/sobre") && !url.includes("/planos");
}

function navigateOnHome(id: string): void {
  if (isHome(window.location.href)) {
    animateScrollTo(document.getElementById(id), {
      speed: 500,
    });
  } else {
    navigate(`/#${id}`)
  }
}

function navigateToPage(page: string): void {
  navigate(page);
}

function navigateToLink(link: string): void {
  window.open(link, "_blank").focus();
}

export { isHome, navigateOnHome, navigateToPage, navigateToLink };