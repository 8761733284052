import { makeStyles } from '@material-ui/core';
import footerBackground from '../../assets/images/footer-background.png';

const useStyles = makeStyles(theme => ({
  footer: {
    width: '100%',
    height: 400,
    display: 'flex',
    backgroundColor: "transparent",
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 28,
    backgroundImage: `url(${footerBackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    zIndex: 1000,
    [theme.breakpoints.down("sm")]: {
      height: 730,
    }
  },
  logoArea: {
    display: 'flex',
    position: 'absolute',
  },
  logo: {
    "&:hover": {
      cursor: 'pointer'
    }
  },
  mainArea: {
    flex: 1,
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  footerContentArea: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 40,
  },
  buttonsArea: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 32,
    marginBottom: 32,
    [theme.breakpoints.down("sm")]: {
      flexDirection: 'column'
    }
  },
  linkText: {
    fontWeight: 'bold',
    marginLeft: 42,
    marginRight: 42,
    "&:hover": {
      cursor: 'pointer'
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 30,
    }
  },
  buySingleBoxButton: {
    width: 165,
    marginLeft: 42,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginTop: 30,
    },
    color: theme.palette.text.primary,
    backgroundColor: "#57BEBF",
    "&:hover": {
      backgroundColor: "#57BEBF"
    }
  },
  acquireButton: {
    width: 165,
    marginLeft: 42,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginTop: 30,
    },
    color: theme.palette.primary.main,
    backgroundColor: "#FFF",
    "&:hover": {
      backgroundColor: "#edebeb"
    }
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: '#4CB5EA'
  },
  infoArea: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down("sm")]: {
      flexDirection: 'column',
    }
  },
  infoText: {
    color: "#BFE6FF",
    [theme.breakpoints.down("sm")]: {
      textAlign: 'center',
    }
  },

  boldText: {
    color: theme.palette.text.primary,
    fontWeight: 'bold'
  },
  innerheadText: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    fontWeight: 'bold',
  },
  socialButton: {
    color: theme.palette.text.primary
  },
  socialIcon: {
    color: theme.palette.text.primary,
  }
}));

export default useStyles;