import { makeStyles } from "@material-ui/core";
import defaultBackground from '../../../assets/images/default-background.png';

const useStyles = makeStyles(theme => ({
  mobileMenu: {
    width: "100vw",
    height: "100vh",
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  mobileMenuTopArea: {
    width: "100%",
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 30,
    paddingTop: 20,
    backgroundImage: `url(${defaultBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: 150
  },
  logoImage: {
    position: 'absolute'
  },
  mobileBoxImage: {
  },
  linksArea: {
    width: "100%",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 32,
  },
  linkItemText: {
    fontFamily: 'Dosis, Roboto',
    fontWeight: 800,
    marginTop: 24,
  },
  buySingleBoxButton: {
    width: 155,
    height: 40,
    backgroundColor: "#FFF",
    color: theme.palette.primary.main,
    marginTop: 24,
  },
  acquireButton: {
    width: 155,
    height: 40,
    backgroundColor: "#F2AF2F",
    color: theme.palette.text.primary,
    marginTop: 24,
  },
  infoArea: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 20,
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 20,
  },
  infoText: {
    color: "#BFE6FF",
    [theme.breakpoints.down("sm")]: {
      textAlign: 'center',
    }
  },

  boldText: {
    color: theme.palette.text.primary,
    fontWeight: 'bold'
  },
  innerheadText: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    fontWeight: 'bold',
  },
  socialButton: {
    color: theme.palette.text.primary
  },
  socialIcon: {
    color: theme.palette.text.primary,
  },
  closeButton: {
    backgroundColor: '#FFF',
    color: theme.palette.primary.main,
    position: 'fixed',
    right: 38,
    zIndex: 6000
  },
  divider: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 24,
      marginBottom: 24,
    }
  }
}));

export default useStyles;