import {
  Button,
  Divider,
  IconButton,
  SwipeableDrawer,
  Typography,
} from "@material-ui/core";
import {
  Close as CloseIcon,
  Instagram as InstagramIcon,
} from "@material-ui/icons";
import { navigate } from "gatsby";
import React from "react";
import logo from "../../../assets/images/logo.png";
import mobileBoxImage from "../../../assets/images/mobile-box-image.png";
import links from "../../../constants/links";
import { SINGLE_SHOP_WEBSITE_URL } from "../../../constants/single-shop-website-url";
import { navigateToLink } from "../../../helpers/navigation-helper/navigation-helper";
import useStyles from "./styles";

interface MobileMenuProps {
  open: boolean;
  onClose: Function;
}

function MobileMenu({ open, onClose }: MobileMenuProps) {
  const classes = useStyles();
  return (
    <SwipeableDrawer anchor="right" open={open} onClose={onClose}>
      <div className={classes.mobileMenu}>
        <div className={classes.mobileMenuTopArea}>
          <div>
            <img
              src={logo}
              alt="Smart Baby Box"
              className={classes.logoImage}
              onClick={() => {
                navigate("/");
                onClose();
              }}
            />
          </div>
          <div>
            <img
              src={mobileBoxImage}
              alt="Box"
              className={classes.mobileBoxImage}
            />
          </div>
          <IconButton className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className={classes.linksArea}>
          {links.map((link, index) => (
            <Typography
              variant="h5"
              key={index}
              className={classes.linkItemText}
              onClick={() => {
                link.onClick();
                onClose();
              }}
            >
              {link.title}
            </Typography>
          ))}
          <div>
            <Button
              variant="contained"
              className={classes.buySingleBoxButton}
              onClick={() => navigateToLink(SINGLE_SHOP_WEBSITE_URL)}
            >
              Avulsos
            </Button>
          </div>
          <div>
            <Button
              variant="contained"
              className={classes.acquireButton}
              onClick={() => {
                navigate("/planos");
                onClose();
              }}
            >
              Assine Já
            </Button>
          </div>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.infoArea}>
          <Typography variant="body1" className={classes.infoText}>
            Todos os direitos reservados{" "}
            <span className={classes.boldText}>Smart Baby Box 2021</span> -
            Desenvolvido por{" "}
            <a href="https://innerhead.com" className={classes.innerheadText}>
              Innerhead
            </a>
          </Typography>
          <div>
            <IconButton
              className={classes.socialButton}
              onClick={() =>
                navigateToLink("https://www.instagram.com/smartbabyboxbrasil")
              }
            >
              <InstagramIcon className={classes.socialIcon} />
            </IconButton>
          </div>
        </div>
      </div>
    </SwipeableDrawer>
  );
}

export default MobileMenu;
