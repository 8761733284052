import { IconButton } from "@material-ui/core";
import React, { useState } from "react";
import logo from "../../assets/images/logo.png";
import mobileBoxImage from "../../assets/images/mobile-box-image.png";
import useStyles from "./styles";
import { Menu as MenuIcon } from "@material-ui/icons";
import MobileMenu from "./mobile-menu/MobileMenu";
import { navigate } from "gatsby";

function MobileNavbar() {
  const classes = useStyles();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  return (
    <div className={classes.mobileNavbar}>
      <div>
        <img
          src={logo}
          alt="Smart Baby Box"
          onClick={() => navigate("/")}
          className={classes.logo}
        />
      </div>
      <img src={mobileBoxImage} alt="Box" />
      <IconButton
        className={classes.mobileNavbarMenuButton}
        onClick={() => setMobileMenuOpen(true)}
      >
        <MenuIcon />
      </IconButton>
      <MobileMenu
        open={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}
      />
    </div>
  );
}

export default MobileNavbar;
