import { AppBar, Button, Toolbar, Typography } from "@material-ui/core";
import React from "react";
import logo from "../../assets/images/logo.png";
import links from "../../constants/links";
import useStyles from "./styles";
import { Link, navigate } from "gatsby";

function Navbar() {
  const classes = useStyles();
  return (
    <AppBar
      className={classes.navbar}
      position="static"
      elevation={0}
      color="transparent"
    >
      <Toolbar className={classes.toolbar}>
        <div className={classes.logoArea}>
          <Link to="/">
            <img src={logo} className={classes.logo} alt="Smart Baby Box" />
          </Link>
        </div>
        <div className={classes.linksArea}>
          {links.map((link, index) => (
            <div key={index} className={classes.linkItem}>
              <Typography
                variant="h6"
                className={classes.linkText}
                onClick={() => link.onClick()}
              >
                {link.title}
              </Typography>
            </div>
          ))}
          <Button
            variant="contained"
            color="primary"
            className={classes.acquireButton}
            onClick={() => navigate("/planos")}
          >
            Assine Já
          </Button>
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
