import {
  Button,
  Divider,
  Hidden,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Instagram as InstagramIcon } from "@material-ui/icons";
import { navigate } from "gatsby";
import React from "react";
import logo from "../../assets/images/logo.png";
import links from "../../constants/links";
import { SINGLE_SHOP_WEBSITE_URL } from "../../constants/single-shop-website-url";
import {
  navigateOnHome,
  navigateToLink,
} from "../../helpers/navigation-helper/navigation-helper";
import useStyles from "./styles";

function Footer() {
  const classes = useStyles();
  return (
    <div className={classes.footer}>
      <div className={classes.logoArea}>
        <img
          src={logo}
          alt="Smart Baby Box"
          className={classes.logo}
          onClick={() => navigateOnHome("home")}
        />
      </div>
      <div className={classes.mainArea}>
        <div className={classes.footerContentArea}>
          <Hidden smDown>
            <Divider className={classes.divider} />
          </Hidden>
          <div className={classes.buttonsArea}>
            {links.map((link, index) => (
              <div key={index} className={classes.linkItem}>
                <Typography
                  variant="body1"
                  className={classes.linkText}
                  onClick={() => link.onClick()}
                >
                  {link.title}
                </Typography>
              </div>
            ))}
            <Hidden smUp>
              <Button
                variant="contained"
                color="primary"
                className={classes.buySingleBoxButton}
                onClick={() => navigateToLink(SINGLE_SHOP_WEBSITE_URL)}
              >
                Avulsos
              </Button>
            </Hidden>
            <Button
              variant="contained"
              color="primary"
              className={classes.acquireButton}
              onClick={() => navigate("/planos")}
            >
              Assine Já
            </Button>
          </div>
          <Divider className={classes.divider} />
        </div>
        <div className={classes.infoArea}>
          <Typography variant="body1" className={classes.infoText}>
            Todos os direitos reservados{" "}
            <span className={classes.boldText}>
              Smart Baby Box {new Date().getFullYear()}
            </span>{" "}
            - Desenvolvido por{" "}
            <a
              href="https://innerhead.com.br"
              className={classes.innerheadText}
            >
              Innerhead
            </a>
          </Typography>
          <div>
            <IconButton
              className={classes.socialButton}
              onClick={() =>
                navigateToLink("https://www.instagram.com/smartbabyboxbrasil")
              }
            >
              <InstagramIcon className={classes.socialIcon} />
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
