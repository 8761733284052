import { makeStyles } from "@material-ui/core";

//@ts-expect-error
const useStyles = makeStyles(theme => ({
  navbar: {
    width: '100%',
    boxShadow: 'none',
    paddingRight: 112,
    paddingLeft: 122,
    backgroundColor: 'transparent',
  },
  toolbar: {
    width: '100%',
    height: 88,
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    marginTop: 120,
    "&:hover": {
      cursor: 'pointer'
    },
    ['@media (min-width: 1368px) and (max-width: 1440px)']: {
      marginTop: 90,
      width: 100,
    }
  },
  logoArea: {
    flex: 0.2
  },
  linksArea: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
    height: 88,
    paddingLeft: 82,
    borderBottom: `2px solid rgba(255, 255, 255, .20)`,
  },
  linkItem: {
  },
  linkText: {
    fontFamily: "Dosis, Roboto",
    fontWeight: "800",
    color: theme.palette.text.primary,
    "&:hover": {
      cursor: 'pointer',
    },
  },
  acquireButton: {
    width: 165,
    color: theme.palette.primary.main,
    backgroundColor: "#FFF",
    "&:hover": {
      backgroundColor: "#edebeb"
    }

  },
}));

export default useStyles;